import { RESET_ALL_STATE } from '@redux/constants';
import { LOGIN_SUCCESS, LOGIN_ERROR, RESET_LOGIN, CREATE_ACCOUNT_EMAIL_EXISTS, SOCIAL_FACEBOOK_LOGGED_IN, SOCIAL_FACEBOOK_LOGGED_OUT, FETCH_FORGOT_PASSWORD_REQUEST, FETCH_FORGOT_PASSWORD_DONE, FETCH_FORGOT_PASSWORD_FAILURE, RESET_FORGOT_PASSWORD, LOG_OUT, LOGIN_REQUEST, CREATE_USER_TYPE, GUEST_EMAIL, SWITCH_ACCOUNT } from '@redux/constants/authentication';
import { IAuthenticationPayload } from '@schemas/IAuthentication';

type Props = {
  type: string;
  payload: IAuthenticationPayload;
};

interface IAuthenticationState {}

const STATE_TEMPLATE: IAuthenticationState = {
  access_token: null,
  authType: CREATE_USER_TYPE.EMAIL,
  signUp: false,
  signUpDone: false,
  signUpError: false,
  loginError: false,
  networkError: false,
  status: 'idle',
  forgotPassword: {
    status: 'idle',
    data: null
  },
  guestEmail: null
};

const INITIAL_STATE: IAuthenticationState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        ...state
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        status: 'loading'
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        access_token: payload.access_token,
        loginError: false,
        authType: payload.authType,
        status: 'done'
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: true,
        status: 'idle'
      };
    case RESET_LOGIN:
      return {
        ...STATE_TEMPLATE
      };
    case CREATE_ACCOUNT_EMAIL_EXISTS:
      return {
        ...state,
        signUpError: payload,
        status: 'idle'
      };
    case SOCIAL_FACEBOOK_LOGGED_IN:
      return {
        ...state,
        authType: CREATE_USER_TYPE.FACEBOOK
      };
    case SOCIAL_FACEBOOK_LOGGED_OUT:
      return {
        ...state,
        authType: CREATE_USER_TYPE.EMAIL
      };
    case FETCH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          status: 'loading',
          data: null
        }
      };
    case FETCH_FORGOT_PASSWORD_DONE:
      return {
        ...state,
        forgotPassword: {
          status: 'done',
          data: payload
        }
      };
    case FETCH_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          status: 'error',
          data: payload
        }
      };
    case RESET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          status: 'idle',
          data: null
        }
      };
    case GUEST_EMAIL:
      return {
        ...state,
        guestEmail: payload
      };
    case LOG_OUT:
      return {
        ...STATE_TEMPLATE
      };
    case SWITCH_ACCOUNT:
      return {
        ...STATE_TEMPLATE
      };
    default:
      return state;
  }
};
