import React from 'react';
import { ICurrentLanguage, ILanguages } from '@schemas/localizejs/LocalizejsTypes';

export type LocalizejsContextType = {
  currentLanguage: ICurrentLanguage;
  languages: ILanguages[];
  updateCurrentLanguage: (language: ICurrentLanguage) => void;
};

declare global {
  interface Window {
    Localize: any;
    dataLayer: any;
  }
}

window.Localize = window.Localize || {
  language: 'en',
  getLanguage: () => {
    return window.Localize.language;
  },
  setLanguage: (lang: string) => {
    window.Localize.language = lang;
  }
};

export const LocalizejsContext = React.createContext<LocalizejsContextType | null>(null);

const LocalizejsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = React.useState<ICurrentLanguage>({ name: window.Localize.getLanguage() });
  const [languages] = React.useState<ILanguages[]>([{ code: 'en', name: 'English', shortCode: 'en' }, { code: 'fr-CA', name: 'Français (Canada)', shortCode: 'fr' }]);

  const updateCurrentLanguage = (language: ICurrentLanguage) => {
    setCurrentLanguage(language);
    window.Localize.setLanguage(language.name);
  };
  return <LocalizejsContext.Provider value={{ currentLanguage, languages, updateCurrentLanguage }}>{children}</LocalizejsContext.Provider>;
};

export default LocalizejsProvider;
