export enum CART_VOUCHER_VALID {
  IDLE = 'idle',
  LOADING = 'loading',
  VALID = 'valid',
  INVALID = 'invalid'
}

export const FETCH_CART_REQUEST: 'FETCH_CART_REQUEST' = 'FETCH_CART_REQUEST';
export const FETCH_CART_DONE: 'FETCH_CART_DONE' = 'FETCH_CART_DONE';
export const FETCH_CART_FAILURE: 'FETCH_CART_FAILURE' = 'FETCH_CART_FAILURE';
