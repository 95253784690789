import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_USER_REQUEST, FETCH_USER_DONE, FETCH_USER_FAILURE, POST_SAVE_WAIVER, FETCH_USER_UPDATE } from '../constants/user';
import { IUser } from '@schemas/IUser';
import { fetchUser, postSaveWaiver } from '../api/user';

export default [takeLatest(FETCH_USER_REQUEST, fetchUsers), takeLatest(POST_SAVE_WAIVER, saveWaiver)];

type FetchUsers = {};
function* fetchUsers(): FetchUsers {
  try {
    const response: IUser = yield call(fetchUser);
    yield put({ type: FETCH_USER_DONE, payload: response });
  } catch (err) {
    yield put({ type: FETCH_USER_FAILURE });
  }
}

type SaveWaiver = {
  type: string;
  payload: {
    resolve: (user: any) => void;
    reject: (error: any) => void;
    user: any;
  };
};
function* saveWaiver({ payload: { resolve, reject, user } }: SaveWaiver) {
  try {
    const response: IUser = yield call(postSaveWaiver, user);
    resolve(response);
    yield put({ type: FETCH_USER_UPDATE, payload: response });
  } catch (err) {
    reject(err);
    yield put({ type: FETCH_USER_FAILURE });
  }
}
