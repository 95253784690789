import { takeLatest, call, put } from 'redux-saga/effects';
import { getAvailability, getNextAvailableSlots } from '@utils/helpers';
import { FETCH_SLOTS_REQUEST, FETCH_SLOTS_DONE, FETCH_SLOTS_FAILURE, FETCH_SLOTS_PER_DAY_REQUEST, FETCH_SLOTS_PER_DAY_DONE, FETCH_SLOTS_PER_DAY_FAILURE, FETCH_YERVANA_WAIVER_REQUEST, FETCH_YERVANA_WAIVER_DONE, FETCH_CARDS_REQUEST, FETCH_CARDS_DONE, POST_CARD_REQUEST, POST_CARD_DONE, POST_WAIVER_REQUEST, POST_BOOKING_COMPLETE_REQUEST, POST_BOOKING_COMPLETE_FAILURE, POST_BOOKING_COMPLETE_DONE, POST_CARD_FAILURE, FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST, FETCH_SLOTS_NEXT_AVAILABILITY_DONE, FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE, FETCH_CARDS_FAILURE } from '../constants/booking';
import { fetchSlotsAPI, fetchYervanaWaiverAPI, fetchCardsAPI, postCardAPI, postWaiverAPI, postCompleteBookingAPI } from '../api/booking';
import { IWaiver } from '@schemas/IWaiver';
import { IBookingCards, IBookingComplete, IBookingNewCardPayload, IBookingWithBookingId } from '@schemas/IBooking';

const moment = require('moment-timezone');

export default [takeLatest(FETCH_SLOTS_REQUEST, getSlots), takeLatest(FETCH_SLOTS_PER_DAY_REQUEST, getSlotsPerDay), takeLatest(FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST, getSlotsNextAvailability), takeLatest(FETCH_YERVANA_WAIVER_REQUEST, getYervanaWaiver), takeLatest(FETCH_CARDS_REQUEST, getCards), takeLatest(POST_WAIVER_REQUEST, postWaiver), takeLatest(POST_CARD_REQUEST, saveCard), takeLatest(POST_BOOKING_COMPLETE_REQUEST, bookingComplete)];

type FetchSlots = {
  type: string;
  payload: {
    resolve: (slots: ISlot[] | any | null) => void;
    reject: (error: any) => void;
    id: number;
    startDate: number;
    endDate: number;
    guests: number;
    adventure?: IAdventure;
  };
};
function* getSlots({ payload: { resolve, reject, id, startDate, endDate, guests } }: FetchSlots) {
  try {
    const slots: ISlot[] = yield call(fetchSlotsAPI, id, startDate, endDate, guests);
    const availability = getAvailability(slots);
    resolve(availability);
    yield put({ type: FETCH_SLOTS_DONE, payload: availability });
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_SLOTS_FAILURE });
  }
}

function* getSlotsPerDay({ payload: { resolve, reject, id, startDate, endDate, guests } }: FetchSlots) {
  try {
    const slots: ISlot[] = yield call(fetchSlotsAPI, id, startDate, endDate, guests);
    resolve(getAvailability(slots));
    yield put({ type: FETCH_SLOTS_PER_DAY_DONE, payload: getAvailability(slots) });
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_SLOTS_PER_DAY_FAILURE });
  }
}

function* getSlotsNextAvailability({ payload: { resolve, reject, id, startDate, endDate, guests, adventure } }: FetchSlots) {
  try {
    const slots: ISlot[] = yield call(fetchSlotsAPI, id, startDate, endDate, guests);
    if (adventure) {
      const from = moment(startDate).toDate();
      const to = moment(endDate).toDate();
      const availSlots = getNextAvailableSlots(slots, adventure.timezone, adventure.availabilityCutoff, from, to);
      const availableSlot = availSlots.length > 0 ? availSlots.slice(0, 1) : [];
      resolve(availableSlot);
      yield put({ type: FETCH_SLOTS_NEXT_AVAILABILITY_DONE, payload: availableSlot });
    }
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE, payload: error });
  }
}

type FetchYervanaWaiver = {
  type: string;
  payload: {
    resolve: (waiver: any) => void;
    reject: (error: any) => void;
    lang: any | null;
  };
};
function* getYervanaWaiver({ payload: { resolve, reject, lang } }: FetchYervanaWaiver) {
  try {
    const waiver: IWaiver = yield call(fetchYervanaWaiverAPI, lang);
    resolve(waiver);
    yield put({ type: FETCH_YERVANA_WAIVER_DONE, payload: waiver });
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_SLOTS_FAILURE });
  }
}

function* getCards() {
  try {
    const cards: IBookingCards = yield call(fetchCardsAPI);
    yield put({ type: FETCH_CARDS_DONE, payload: cards });
  } catch (error) {
    yield put({ type: FETCH_CARDS_FAILURE });
  }
}

type Waiver = {
  type: string;
  payload: {
    lang: string;
    data: any;
    cardId: string;
    code: string;
  };
};
function* postWaiver({ payload: { lang, data, cardId, code } }: Waiver) {
  try {
    const bookingResponse: IBookingWithBookingId = yield call(postWaiverAPI, lang, data);
    yield put({ type: POST_BOOKING_COMPLETE_REQUEST, payload: { id: bookingResponse.booking.id, cardId, code } });
  } catch (error) {
    const message = (error as any).data.response;
    yield put({ type: POST_BOOKING_COMPLETE_FAILURE, payload: message });
  }
}

type PostCard = {
  type: string;
  payload: {
    resolve: (waiver: any) => void;
    reject: (error: any) => void;
    token: string;
  };
};
function* saveCard({ payload: { resolve, reject, token } }: PostCard) {
  try {
    const newCard: IBookingNewCardPayload = yield call(postCardAPI, token);
    resolve(newCard);
    const cardId = newCard.id;
    yield put({ type: POST_CARD_DONE, payload: cardId });
    yield put({ type: FETCH_CARDS_REQUEST });
  } catch (error) {
    reject(error);
    yield put({ type: POST_CARD_FAILURE, payload: error });
  }
}

type BookingComplete = {
  type: string;
  payload: {
    id: number;
    cardId: string;
    code: string;
  };
};
function* bookingComplete({ payload: { id, cardId, code } }: BookingComplete) {
  try {
    const completeResponse: IBookingComplete = yield call(postCompleteBookingAPI, id, cardId, code);
    const bookingComplete: IBookingComplete = {
      status: 'done',
      id: completeResponse.id,
      dateTime: completeResponse.dateTime,
      discountAmount: completeResponse.discountAmount,
      guestCount: completeResponse.guestCount,
      feeToYervana: completeResponse.feeToYervana,
      pricePaid: completeResponse.pricePaid,
      receiptDTO: completeResponse.receiptDTO,
      bookingStatus: completeResponse.status,
      error: null
    };
    yield put({ type: POST_BOOKING_COMPLETE_DONE, payload: bookingComplete });
  } catch (error) {
    yield put({ type: POST_BOOKING_COMPLETE_FAILURE, payload: { error } });
  }
}
