import { PREFERENCES_WIDGET_TYPE, PREFERENCES_INITIAL_SEARCH, PREFERENCES_WIDGET_MOBILE_OPEN_NEW_WINDOW, PREFERENCES_IS_DIRECT_BOOKING } from '@redux/constants/preferences';
import { IPreferences } from '@schemas/IPreferences';

type Props = {
  type: string;
  payload: IPreferences;
};

interface IPreferencesState {}

const STATE_TEMPLATE: IPreferencesState = {
  widgetType: null,
  initialSearch: '',
  mobileOpenNewWindow: false,
  isDirectBooking: false
};

const INITIAL_STATE: IPreferencesState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case PREFERENCES_WIDGET_TYPE:
      return {
        ...state,
        widgetType: payload
      };
    case PREFERENCES_INITIAL_SEARCH:
      return {
        ...state,
        initialSearch: payload
      };
    case PREFERENCES_WIDGET_MOBILE_OPEN_NEW_WINDOW:
      return {
        ...state,
        mobileOpenNewWindow: payload
      };
    case PREFERENCES_IS_DIRECT_BOOKING:
      return {
        ...state,
        isDirectBooking: payload
      };
    default:
      return state;
  }
};
