import React from 'react';
import GA4React from 'ga-4-react';

export type GoogleAnalyticsContextType = {
  ready: boolean;
};

export const GoogleAnalyticsContext = React.createContext<GoogleAnalyticsContextType | null>(null);

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_TAG_MANAGER as string);

const GoogleAnalyticsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [ready, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      await ga4react.initialize();
      setReady(true);
    })();
  }, []);

  return <GoogleAnalyticsContext.Provider value={{ ready }}>{ready ? children : null}</GoogleAnalyticsContext.Provider>;
};

export default GoogleAnalyticsProvider;
