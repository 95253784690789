import { API_URL } from '@constants/general';
import axios from '@redux/sagas/axios.config';
import { IWaiver } from '@schemas/IWaiver';
export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APIFetchSlots = (id: number, startDate: number, endDate: number, guests: number) => void;
export const fetchSlotsAPI: APIFetchSlots = async (id: number, startDate: number, endDate: number, guests: number) => {
  try {
    const response = await fetch(`${API_URL}experiences/${id}/slots?startDate=${startDate}&endDate=${endDate}&guests=${guests}`);
    if (!response.ok) {
      return Promise.reject(response.statusText);
    } else {
      return await response.json();
    }
  } catch (error) {
    throw new RequestError(error);
  }
};

type APIPostWaiver = (lang: string, data: FormData) => IWaiver;
export const postWaiverAPI: APIPostWaiver = async (lang: string, data: FormData) => {
  return axios
    .post(`waiver/user?lang=${lang}`, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

type APIFetchYervanaWaiver = (lang: string) => IWaiver;
export const fetchYervanaWaiverAPI: APIFetchYervanaWaiver = async (lang: string) => {
  return axios
    .get(`waiver/anon?lang=${lang}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

type APIFetchCards = () => any;
export const fetchCardsAPI: APIFetchCards = async () => {
  return axios
    .get('stripe/cards')
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

type APIPostCard = (token: string) => any;
export const postCardAPI: APIPostCard = async (token: string) => {
  return axios
    .post('stripe/payment-method', { token })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err.data.response);
    });
};

type APICompleteBooking = (id: number, cardId: string, code: string) => any;
export const postCompleteBookingAPI: APICompleteBooking = async (id: number, cardId: string, code: string) => {
  return axios
    .post(`bookings/${id}/complete`, { cardId, code, isWidget: true })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
