import { takeLatest, call, put } from 'redux-saga/effects';
import { LOG_IN, CREATE_ACCOUNT, CREATE_GUEST_ACCOUNT, CREATE_ACCOUNT_EMAIL_EXISTS, FETCH_FORGOT_PASSWORD_REQUEST, FETCH_FORGOT_PASSWORD_DONE, FETCH_FORGOT_PASSWORD_FAILURE, LOGIN_WITH_FACEBOOK, LOGIN_SUCCESS, LOGIN_ERROR, CREATE_USER_TYPE, LOGIN_WITH_GOOGLE } from '../constants/authentication';
import { IAuthenticationPayload, IForgotPasswordPayload } from '@schemas/IAuthentication';
import { fetchLogin, postCreateAccount, postCreateGuestAccount, postForgotPassword, loginWithFacebook, loginWithGoogle } from '../api/authentication';

export default [takeLatest(LOG_IN, logInWithBasic), takeLatest(CREATE_ACCOUNT, createAccount), takeLatest(CREATE_GUEST_ACCOUNT, createGuestAccount), takeLatest(FETCH_FORGOT_PASSWORD_REQUEST, forgotPassword), takeLatest(LOGIN_WITH_FACEBOOK, facebookLogin), takeLatest(LOGIN_WITH_GOOGLE, googleLogin)];

type FetchUser = {
  type: string;
  payload: {
    data: any;
  };
};

function* logInWithBasic({ payload: { data } }: FetchUser) {
  try {
    const authenticationPayload: IAuthenticationPayload = yield call(fetchLogin, data);
    yield put({ type: LOGIN_SUCCESS, payload: { access_token: authenticationPayload.access_token, authType: CREATE_USER_TYPE.EMAIL } });
  } catch (error) {
    yield put({ type: LOGIN_ERROR });
  }
}

function* forgotPassword({ payload: { data } }: FetchUser) {
  try {
    const forgotPasswordPayload: IForgotPasswordPayload = yield call(postForgotPassword, data);
    yield put({ type: FETCH_FORGOT_PASSWORD_DONE, payload: forgotPasswordPayload });
  } catch (error) {
    yield put({ type: FETCH_FORGOT_PASSWORD_FAILURE, payload: error });
  }
}

function* createAccount({ payload: { data } }: FetchUser) {
  try {
    const response: IAuthenticationPayload = yield call(postCreateAccount, data);
    yield put({ type: LOGIN_SUCCESS, payload: { access_token: response.access_token, authType: CREATE_USER_TYPE.EMAIL } });
  } catch (error) {
    yield put({ type: CREATE_ACCOUNT_EMAIL_EXISTS, payload: true });
  }
}

function* createGuestAccount({ payload: { data } }: FetchUser) {
  try {
    const response: IAuthenticationPayload = yield call(postCreateGuestAccount, data);
    yield put({ type: LOGIN_SUCCESS, payload: { access_token: response.access_token, authType: CREATE_USER_TYPE.GUEST } });
  } catch (error) {
    yield put({ type: CREATE_ACCOUNT_EMAIL_EXISTS, payload: true });
  }
}

function* facebookLogin({ payload: { data } }: FetchUser) {
  try {
    const facebookLogin: IAuthenticationPayload = yield call(loginWithFacebook, data);
    yield put({ type: LOGIN_SUCCESS, payload: { access_token: facebookLogin.access_token, authType: CREATE_USER_TYPE.FACEBOOK } });
  } catch (error) {}
}

function* googleLogin({ payload: { data } }: FetchUser) {
  try {
    const googleLogin: IAuthenticationPayload = yield call(loginWithGoogle, data);
    yield put({ type: LOGIN_SUCCESS, payload: { access_token: googleLogin.access_token, authType: CREATE_USER_TYPE.GOOGLE } });
  } catch (error) {}
}
