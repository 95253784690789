import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_ADVENTURE_REQUEST, FETCH_ADVENTURE_DONE, FETCH_ADVENTURE_FAILURE } from '../constants/adventure';
import { FETCH_CART_REQUEST } from '@redux/constants/cart';
import { fetchAdventureAPI } from '../api/adventure';

export default [takeLatest(FETCH_ADVENTURE_REQUEST, fetchAdventure)];

type FetchAdventure = {
  type: string;
  payload: {
    resolve: (adventures: IAdventure) => void;
    reject: (error: any) => void;
    adventureId: number;
    authType: string | null;
  };
};

function* fetchAdventure({ payload: { resolve, reject, adventureId, authType } }: FetchAdventure) {
  try {
    const adventure: IAdventure = yield call(fetchAdventureAPI, adventureId);
    resolve(adventure);
    yield put({ type: FETCH_ADVENTURE_DONE, payload: adventure });
    if (authType !== 'guest') {
      yield put({ type: FETCH_CART_REQUEST, payload: { adventureId, explorers: 1, voucher: '' } });
    }
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_ADVENTURE_FAILURE });
  }
}
