import { all } from 'redux-saga/effects';
import authenticationSagas from './authentication';
import userSagas from './user';
import adventuresSagas from './adventures';
import adventureSagas from './adventure';
import bookingSagas from './booking';
import cartSagas from './cart';

export default function* rootSaga() {
  yield all([...authenticationSagas, ...userSagas, ...adventuresSagas, ...adventureSagas, ...bookingSagas, ...cartSagas]);
}
