export enum CREATE_USER_TYPE {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  GUEST = 'guest'
}

export const LOG_OUT: 'LOG_OUT' = 'LOG_OUT';
export const LOG_IN: 'LOG_IN' = 'LOG_IN';
export const SWITCH_ACCOUNT: 'SWITCH_ACCOUNT' = 'SWITCH_ACCOUNT';
export const CREATE_ACCOUNT: 'CREATE_ACCOUNT' = 'CREATE_ACCOUNT';
export const CREATE_GUEST_ACCOUNT: 'CREATE_GUEST_ACCOUNT' = 'CREATE_GUEST_ACCOUNT';
export const CREATE_ACCOUNT_EMAIL_EXISTS: 'CREATE_ACCOUNT_EMAIL_EXISTS' = 'CREATE_ACCOUNT_EMAIL_EXISTS';

export const FETCH_FORGOT_PASSWORD_REQUEST: 'FETCH_FORGOT_PASSWORD_REQUEST' = 'FETCH_FORGOT_PASSWORD_REQUEST';
export const FETCH_FORGOT_PASSWORD_DONE: 'FETCH_FORGOT_PASSWORD_DONE' = 'FETCH_FORGOT_PASSWORD_DONE';
export const FETCH_FORGOT_PASSWORD_FAILURE: 'FETCH_FORGOT_PASSWORD_FAILURE' = 'FETCH_FORGOT_PASSWORD_FAILURE';
export const RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD' = 'RESET_FORGOT_PASSWORD';

export const LOGIN_REQUEST: 'LOGIN_REQUEST' = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS: 'LOGIN_SUCCESS' = 'LOGIN_SUCCESS';
export const LOGIN_ERROR: 'LOGIN_ERROR' = 'LOGIN_ERROR';
export const RESET_LOGIN: 'RESET_LOGIN' = 'RESET_LOGIN';
export const GUEST_EMAIL: 'GUEST_EMAIL' = 'GUEST_EMAIL';
export const SOCIAL_FACEBOOK_LOGGED_IN: 'SOCIAL_FACEBOOK_LOGGED_IN' = 'SOCIAL_FACEBOOK_LOGGED_IN';
export const SOCIAL_FACEBOOK_LOGGED_OUT: 'SOCIAL_FACEBOOK_LOGGED_OUT' = 'SOCIAL_FACEBOOK_LOGGED_OUT';

export const LOGIN_WITH_FACEBOOK: 'LOGIN_WITH_FACEBOOK' = 'LOGIN_WITH_FACEBOOK';
export const LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE' = 'LOGIN_WITH_GOOGLE';
