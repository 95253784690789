import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_LIST_ADVENTURES_REQUEST, FETCH_LIST_ADVENTURES_DONE, FETCH_LIST_ADVENTURES_FAILURE } from '../constants/adventures';
import { fetchAdventuresAPI } from '../api/adventures';

export default [takeLatest(FETCH_LIST_ADVENTURES_REQUEST, fetchAdventures)];

type FetchAdventures = {
  type: string;
  payload: {
    resolve: (adventures: IAdventure[]) => void;
    reject: (error: any) => void;
    host: number;
    adventuresIds: number[];
  };
};

function* fetchAdventures({ payload: { resolve, reject, host, adventuresIds } }: FetchAdventures) {
  try {
    const adventures: IAdventure[] = yield call(fetchAdventuresAPI, host, adventuresIds);
    resolve(adventures);
    yield put({ type: FETCH_LIST_ADVENTURES_DONE, payload: adventures });
  } catch (error) {
    reject(error);
    yield put({ type: FETCH_LIST_ADVENTURES_FAILURE });
  }
}
