import { RESET_ALL_STATE } from '@redux/constants';
import { FETCH_CART_REQUEST, FETCH_CART_DONE, FETCH_CART_FAILURE } from '@redux/constants/cart';
import { ICart } from '@schemas/ICart';

type Props = {
  type: string;
  payload: ICart;
};

interface ICartState {}

const STATE_TEMPLATE: ICartState = {
  currency: null,
  discount: null,
  gift: null,
  id: null,
  lineItems: [],
  simulated: false,
  totals: null,
  userId: null,
  voucher: {
    code: null,
    valid: false,
    effect: null
  },
  status: 'idle',
  error: null
};

const INITIAL_STATE: ICartState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        ...STATE_TEMPLATE
      };
    case FETCH_CART_REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null
      };
    case FETCH_CART_DONE:
      return {
        ...state,
        ...payload,
        status: 'done',
        error: null
      };
    case FETCH_CART_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: 'Something went wrong'
      };
    default:
      return state;
  }
};
