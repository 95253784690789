import axios from '@redux/sagas/axios.config';

export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APIUser = () => Promise<any>;

export const fetchUser: APIUser = async () => {
  return axios
    .get('/users')
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

type APISaveWaiver = (data: any) => any;
export const postSaveWaiver: APISaveWaiver = async (user: any) => {
  return axios
    .post('/waiver/save', user)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const fetchCountry: APIUser = async () => {
  return axios
    .get('/country')
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

type APIFetchState = (id: string) => Promise<any>;
export const fetchState: APIFetchState = async (id: string) => {
  return axios
    .get(`/states?id=${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
