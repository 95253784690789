import React from 'react';
import { Spin } from 'antd';

type Props = {
  label?: string;
};

const Loader: React.FC<Props> = ({ label = 'Loading' }) => {
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Spin tip={label} />
    </div>
  );
};

export default Loader;
