import { API_URL } from '@constants/general';
import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = API_URL;

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: { response: { status: number } }) => {
    const state = store.getState();
    if (error.response.status === 401 && state.authentication && state.authentication.access_token) {
      store.dispatch({ type: 'SHOW_LOGIN_MODAL', data: { reason: 'TOKEN_EXPIRED' } });
    }
    return Promise.reject(error.response || error);
  }
);

axios.interceptors.request.use(
  (config: any) => {
    const state = store.getState();
    const token = state.authentication.access_token;
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error: any) => Promise.reject(error)
);

export default axios;
