import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import preferences from './preferences';
import authentication from './authentication';
import user from './user';
import adventures from './adventures';
import adventure from './adventure';
import booking from './booking';
import cart from './cart';
import social from './social';

export default (history: History) =>
  combineReducers<IReducerStates>({
    preferences,
    authentication,
    user,
    adventures,
    adventure,
    booking,
    cart,
    social,
    router: connectRouter(history)
  });
