export const FETCH_USER_REQUEST: 'FETCH_USER_REQUEST' = 'FETCH_USER_REQUEST';
export const FETCH_USER_DONE: 'FETCH_USER_DONE' = 'FETCH_USER_DONE';
export const FETCH_USER_FAILURE: 'FETCH_USER_FAILURE' = 'FETCH_USER_FAILURE';
export const FETCH_USER_UPDATE: 'FETCH_USER_UPDATE' = 'FETCH_USER_UPDATE';

export const POST_SAVE_WAIVER: 'POST_SAVE_WAIVER' = 'POST_SAVE_WAIVER';

export const FETCH_COUNTRIES: 'FETCH_COUNTRIES' = 'FETCH_COUNTRIES';
export const FETCH_STATES: 'FETCH_STATES' = 'FETCH_STATES';

export const GUEST_USER: 'GUEST_USER' = 'GUEST_USER';
