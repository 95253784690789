import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_CART_REQUEST, FETCH_CART_DONE, FETCH_CART_FAILURE } from '../constants/cart';
import { ICartPayload } from '@schemas/ICart';
import { fetchCart } from '../api/cart';

export default [takeLatest(FETCH_CART_REQUEST, getCart)];

type FetchCart = {
  type: string;
  payload: {
    adventureId: number;
    explorers: number;
    voucher: string;
  };
};

function* getCart({ payload: { adventureId, explorers, voucher } }: FetchCart) {
  try {
    const cartPayload: ICartPayload = yield call(fetchCart, adventureId, explorers, voucher);
    yield put({ type: FETCH_CART_DONE, payload: cartPayload.data });
  } catch (err) {
    yield put({ type: FETCH_CART_FAILURE });
  }
}
