import axios from '@redux/sagas/axios.config';

export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APICart = (adventureId: number, explorers: number, voucher: string) => Promise<any>;

export const fetchCart: APICart = async (adventureId: number, explorers: number, voucher: string) => {
  return axios
    .get(`/bookings/cart?isWidget=true&adventureId=${adventureId}&explorers=${explorers}&voucher=${voucher}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
