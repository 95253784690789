import { IBooking, IBookingData } from '@schemas/IBooking';
import { BOOKING_CURRENT_STEP, BOOKING_CURRENT_DETAILS_STEP, BOOKING_UPDATE_PARAMETERS, BOOKING_SELECTED_SLOT, BOOKING_UPDATE_DETAILS, FETCH_SLOTS_REQUEST, FETCH_SLOTS_DONE, FETCH_YERVANA_WAIVER_REQUEST, FETCH_YERVANA_WAIVER_DONE, FETCH_CARDS_REQUEST, FETCH_CARDS_DONE, CURRENT_STEPS, CURRENT_DETAILS_STEP, POST_CARD_REQUEST, POST_CARD_DONE, FETCH_SLOTS_PER_DAY_REQUEST, FETCH_SLOTS_PER_DAY_DONE, CURRENT_DETAILS_AUTHENTICATION_METHOD, BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD, BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD_OPEN, BOOKING_SET_WAIVER, POST_BOOKING_COMPLETE_REQUEST, POST_BOOKING_COMPLETE_DONE, POST_BOOKING_COMPLETE_FAILURE, BOOKING_SET_ADDITIONAL_GUESTS, POST_CARD_FAILURE, BOOKING_COMPLETED_STEPS, FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST, FETCH_SLOTS_NEXT_AVAILABILITY_DONE, FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE, POST_CARD_IS_VISIBLE, POST_CARD_RESET } from '@redux/constants/booking';
import { RESET_ALL_STATE } from '@redux/constants';
import { LOG_OUT, SWITCH_ACCOUNT } from '@redux/constants/authentication';

const moment = require('moment');

type Props = {
  type: string;
  payload: IBooking;
};

interface IBookingState {
  booking: IBooking | null;
  data: IBookingData;
}

const STATE_TEMPLATE: IBookingState = {
  booking: {
    parameters: {
      guests: 1,
      dates: {
        from: moment().valueOf(),
        to: moment()
          .add(1, 'months')
          .valueOf()
      }
    },
    completedSteps: {
      authentication: false,
      guest: false,
      additionalGuests: false,
      waiver: false
    },
    step: CURRENT_STEPS.PARAMETERS,
    authenticationMethod: CURRENT_DETAILS_AUTHENTICATION_METHOD.NONE,
    authenticationMethodOpen: CURRENT_DETAILS_AUTHENTICATION_METHOD.GUEST,
    detailsStep: CURRENT_DETAILS_STEP.LOGIN,
    selectedSlot: null,
    waiver: null,
    additionalGuests: [],
    details: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    complete: {
      status: 'idle',
      id: null,
      dateTime: null,
      guestCount: null,
      discountAmount: null,
      feeToYervana: null,
      pricePaid: 0,
      receiptDTO: null,
      bookingStatus: '',
      error: null
    }
  },
  data: {
    slots: {
      data: [],
      status: 'idle',
      error: null
    },
    slotsPerDay: {
      data: [],
      status: 'idle',
      error: null
    },
    slotsNextAvailability: {
      data: null,
      status: 'idle',
      error: null
    },
    waivers: {
      yervana: null
    },
    card: {
      status: 'idle',
      card: null
    },
    cards: {
      status: 'idle',
      primaryCard: null,
      cards: []
    },
    addCard: {
      status: 'idle',
      isVisible: false,
      errorMessage: null
    }
  }
};

const INITIAL_STATE: IBookingState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        ...STATE_TEMPLATE
      };
    case BOOKING_COMPLETED_STEPS:
      const completedSteps = state.booking ? state.booking.completedSteps : {};
      return {
        ...state,
        booking: {
          ...state.booking,
          completedSteps: {
            ...completedSteps,
            ...payload
          }
        }
      };
    case BOOKING_CURRENT_STEP:
      return {
        ...state,
        booking: {
          ...state.booking,
          step: payload
        }
      };
    case BOOKING_CURRENT_DETAILS_STEP:
      return {
        ...state,
        booking: {
          ...state.booking,
          detailsStep: payload
        }
      };
    case BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD:
      return {
        ...state,
        booking: {
          ...state.booking,
          authenticationMethod: payload
        }
      };
    case BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD_OPEN:
      return {
        ...state,
        booking: {
          ...state.booking,
          authenticationMethodOpen: payload
        }
      };
    case BOOKING_UPDATE_PARAMETERS:
      return {
        ...state,
        booking: {
          ...state.booking,
          parameters: {
            ...payload.parameters
          }
        }
      };
    case BOOKING_SELECTED_SLOT:
      return {
        ...state,
        booking: {
          ...state.booking,
          selectedSlot: payload.selectedSlot
        }
      };
    case BOOKING_SET_WAIVER:
      return {
        ...state,
        booking: {
          ...state.booking,
          waiver: payload
        }
      };
    case BOOKING_SET_ADDITIONAL_GUESTS:
      return {
        ...state,
        booking: {
          ...state.booking,
          additionalGuests: payload
        }
      };
    case BOOKING_UPDATE_DETAILS:
      return {
        ...state,
        booking: {
          ...state.booking,
          details: payload
        }
      };
    case FETCH_SLOTS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          slots: {
            data: [],
            status: 'loading',
            error: null
          }
        }
      };
    case FETCH_SLOTS_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          slots: {
            data: payload,
            status: 'done',
            error: null
          }
        }
      };
    case FETCH_SLOTS_PER_DAY_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          slotsPerDay: {
            data: [],
            status: 'loading',
            error: null
          }
        }
      };
    case FETCH_SLOTS_PER_DAY_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          slotsPerDay: {
            data: payload,
            status: 'done',
            error: null
          }
        }
      };

    case FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          slotsNextAvailability: {
            data: null,
            status: 'loading',
            error: null
          }
        }
      };
    case FETCH_SLOTS_NEXT_AVAILABILITY_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          slotsNextAvailability: {
            data: payload,
            status: 'done',
            error: null
          }
        }
      };
    case FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          slotsNextAvailability: {
            data: null,
            status: 'done',
            error: payload
          }
        }
      };

    case FETCH_YERVANA_WAIVER_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          waivers: {
            yervana: null
          }
        }
      };
    case FETCH_YERVANA_WAIVER_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          waivers: {
            ...state.data.waivers,
            yervana: payload
          }
        }
      };
    case FETCH_CARDS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          cards: {
            status: 'loading',
            primaryCard: null,
            cards: []
          }
        }
      };
    case FETCH_CARDS_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          cards: {
            ...payload,
            status: 'done'
          }
        }
      };
    case POST_CARD_IS_VISIBLE:
      return {
        ...state,
        data: {
          ...state.data,
          addCard: {
            ...state.data.addCard,
            isVisible: payload
          }
        }
      };
    case POST_CARD_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          addCard: {
            ...state.data.addCard,
            status: 'loading'
          }
        }
      };
    case POST_CARD_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          card: payload,
          addCard: {
            ...state.data.addCard,
            status: 'done',
            isVisible: false
          }
        }
      };
    case POST_CARD_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          addCard: {
            ...state.data.addCard,
            status: 'idle',
            errorMessage: null
          }
        }
      };
    case POST_CARD_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          addCard: {
            ...state.data.addCard,
            status: 'error',
            errorMessage: payload
          }
        }
      };
    case POST_BOOKING_COMPLETE_REQUEST:
      return {
        ...state,
        booking: {
          ...state.booking,
          complete: {
            status: 'loading',
            dateTime: null,
            guestCount: null,
            discountAmount: null,
            feeToYervana: null,
            pricePaid: null,
            receiptDTO: null,
            error: null
          }
        }
      };
    case POST_BOOKING_COMPLETE_DONE:
      return {
        ...state,
        booking: {
          ...state.booking,
          complete: {
            ...payload
          }
        }
      };
    case POST_BOOKING_COMPLETE_FAILURE:
      const bookingComplete = STATE_TEMPLATE.booking ? STATE_TEMPLATE.booking.complete : null;
      return {
        ...state,
        booking: {
          ...state.booking,
          complete: {
            ...bookingComplete,
            error: payload
          }
        }
      };
    case LOG_OUT:
      return {
        ...state,
        booking: {
          ...state.booking,
          authenticationMethod: CURRENT_DETAILS_AUTHENTICATION_METHOD.CREATE
        }
      };
    case SWITCH_ACCOUNT:
      if (STATE_TEMPLATE.booking) {
        return {
          ...state,
          booking: {
            ...state.booking,
            completedSteps: {
              ...STATE_TEMPLATE.booking.completedSteps
            },
            step: CURRENT_STEPS.DETAILS,
            authenticationMethod: CURRENT_DETAILS_AUTHENTICATION_METHOD.NONE,
            authenticationMethodOpen: CURRENT_DETAILS_AUTHENTICATION_METHOD.GUEST,
            detailsStep: CURRENT_DETAILS_STEP.LOGIN,
            details: {
              ...STATE_TEMPLATE.booking.details
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    default:
      return state;
  }
};
