import axios from '@redux/sagas/axios.config';
import { ICreateUser } from '@schemas/IAuthentication';

export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APILogin = (data: any) => Promise<any>;

export const fetchLogin: APILogin = async (data: any) => {
  return axios
    .post('/authenticate', data)
    .then(response => {
      return Promise.resolve({ access_token: response.data.access_token, authType: 'email' });
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const postCreateAccount: APILogin = async (data: ICreateUser) => {
  return axios
    .post('/users', data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const postCreateGuestAccount: APILogin = async (data: ICreateUser) => {
  return axios
    .post('/anonymousUser', data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const postForgotPassword: APILogin = async (data: any) => {
  return axios
    .post('/users/forgot_password', data)
    .then(response => {
      return Promise.resolve({ response: response.data });
    })
    .catch(err => {
      return Promise.reject(err.data.response);
    });
};

type APIUser = (token: string) => Promise<any>;

export const loginWithFacebook: APIUser = async (data: any) => {
  return axios
    .post('/users', { token: data.token, type: data.type })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const loginWithGoogle: APIUser = async (data: any) => {
  return axios
    .post('/users', { token: data.token, type: data.type })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
