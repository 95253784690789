import { ISocial, ISocialFacebook, ISocialGoogle } from '@schemas/ISocial';
import { SOCIAL_FACEBOOK_INIT, SOCIAL_FACEBOOK_LOCK, SOCIAL_FACEBOOK_RELEASE, SOCIAL_GOOGLE_INIT, SOCIAL_GOOGLE_LOCK, SOCIAL_GOOGLE_RELEASE } from '@redux/constants/social';
import { RESET_ALL_STATE } from '@redux/constants';

// const moment = require('moment');

type Props = {
  type: string;
  payload: ISocial;
};

interface ISocialState {
  facebook: ISocialFacebook;
  google: ISocialGoogle;
}

const STATE_TEMPLATE: ISocialState = {
  facebook: {
    loggedIn: false,
    sdkLoaded: false,
    isProcessing: false,
    scope: null,
    fields: null,
    appId: null,
    returnScopes: null,
    reAuthenticate: false,
    error: null
  },
  google: {
    loggedIn: false,
    sdkLoaded: false,
    isProcessing: false,
    scope: null,
    fields: null,
    appId: null,
    returnScopes: null,
    reAuthenticate: false,
    error: null
  }
}

const INITIAL_STATE: ISocialState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        ...STATE_TEMPLATE
      };
    case SOCIAL_FACEBOOK_INIT:
      return {
        ...state,
        facebook: {
          ...state.facebook,
          ...payload,
          sdkLoaded: true
        }
      };
    case SOCIAL_FACEBOOK_LOCK:
      return {
        ...state,
        facebook: {
          ...state.facebook,
          isProcessing: true
        }
      };
    case SOCIAL_FACEBOOK_RELEASE:
      return {
        ...state,
        facebook: {
          ...state.facebook,
          isProcessing: false
        }
      };

    case SOCIAL_GOOGLE_INIT:
      return {
        ...state,
        google: {
          ...state.google,
          ...payload,
          sdkLoaded: true
        }
      };
    case SOCIAL_GOOGLE_LOCK:
      return {
        ...state,
        google: {
          ...state.google,
          isProcessing: true
        }
      };
    case SOCIAL_GOOGLE_RELEASE:
      return {
        ...state,
        google: {
          ...state.google,
          isProcessing: false
        }
      };
    default:
      return state;
  }
};
