import { RESET_ALL_STATE } from '@redux/constants';
import {
  FETCH_ADVENTURE_REQUEST,
  FETCH_ADVENTURE_DONE,
  FETCH_ADVENTURE_FAILURE
} from '@redux/constants/adventure';

type Props = {
  type: string;
  payload: IAdventure;
};

interface IAdventureState {
  adventure: IAdventure | null;
  status: string;
  error: string | null;
}

const INITIAL_STATE: IAdventureState = {
  adventure: null,
  status: 'idle',
  error: null
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        adventure: null,
        status: 'idle',
        error: null
      };
    case FETCH_ADVENTURE_REQUEST:
      return {
        adventure: null,
        status: 'loading',
        error: null
      };
    case FETCH_ADVENTURE_DONE:
      return {
        adventure: payload,
        status: 'done',
        error: null
      };
    case FETCH_ADVENTURE_FAILURE:
      return {
        adventure: null,
        status: 'error',
        error: 'Error loading experiences'
      };
    default:
      return state;
  }
};
