import React from 'react';
import throttle from 'lodash/throttle';
import { IBreakpoint, IWindowSize } from '@schemas/media/MediaTypes';

const breakpoints = [{ name: 'x-small', min: 1, max: 428, type: 'mobile' }, { name: 'small', min: 429, max: 769, type: 'mobile' }, { name: 'medium', min: 770, max: 1024, type: 'tablet' }, { name: 'big', min: 1025, max: 1200, type: 'desktop' }, { name: 'large', min: 1201, max: 5000, type: 'desktop' }];

const useMedia = () => {
  const [media, setMedia] = React.useState<IBreakpoint | null>(null);
  const [windowSize, setWindowSize] = React.useState<IWindowSize>({ width: 0, height: 0 });

  const calculateWindowSize = () => {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setWindowSize({ width: w, height: h });
    return { width: w, height: h };
  };

  const onResize = () => {
    const size = calculateWindowSize();
    for (let index = 0; index < breakpoints.length; index++) {
      const breakpoint: IBreakpoint = breakpoints[index];
      if (breakpoint.min && breakpoint.max) {
        if (size.width > breakpoint.min && size.width <= breakpoint.max) {
          setMedia(breakpoint);
        }
      }
    }
  };

  const handleThrottled = throttle(() => {
    onResize();
  }, 500);

  React.useEffect(() => {
    onResize();
    window.addEventListener('resize', handleThrottled);
    return () => {
      window.removeEventListener('resize', handleThrottled);
      handleThrottled.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postMessage = (height: number) => {
    window.parent.postMessage({ name: 'handleOnHeightChange', height }, '*');
  };

  return { media, windowSize, postMessage };
};

export default useMedia;
