import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from '@redux/store';
import LocalizejsProvider from '@contexts/LocalizejsContext';
import MediaProvider from '@contexts/MediaContext';
import GoogleAnalyticsProvider from '@contexts/GoogleAnalyticsContext';
import Loader from '@components/ui/Loader';
import TagManager from 'react-gtm-module';

const Home = React.lazy(() => import('../screens/home/Home'));
const Adventures = React.lazy(() => import('../screens/adventures/Adventures'));
const Checkout = React.lazy(() => import('../screens/checkout/Checkout'));
const Thanks = React.lazy(() => import('../screens/thanks/Thanks'));

const publicPaths = [{ exact: true, path: '/', component: Home }, { exact: true, path: '/booknow/adventures', component: Adventures }, { exact: true, path: '/embedded/adventures', component: Adventures }, { exact: false, path: '/(embedded|booknow)?/checkout/:id/calendar', component: Checkout }, { exact: true, path: '/:id/thanks', component: Thanks }];

const publicRoutes = publicPaths.map(({ path, ...props }) => <Route key={path} path={path} {...props} />);

const tagMangerArgs = {
  gtmId: 'GTM-TXJLB6W'
};
TagManager.initialize(tagMangerArgs);
const Routes = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleAnalyticsProvider>
          <MediaProvider>
            <LocalizejsProvider>
              <Switch>
                <Suspense
                  fallback={
                    <div style={{ height: '100%' }}>
                      <Loader />
                    </div>
                  }
                >
                  {publicRoutes}
                  {/* <Route component={NotFound} /> */}
                </Suspense>
              </Switch>
            </LocalizejsProvider>
          </MediaProvider>
        </GoogleAnalyticsProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default Routes;
