import { API_URL } from '@constants/general';

export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APIFetchAdventures = (host: number, adventuresIds: number[]) => void;

export const fetchAdventuresAPI: APIFetchAdventures = async (host: number, adventuresIds: number[]) => {
  try {
    const response = await fetch(`${API_URL}experiences/ordered?hostId=${host}&experienceIds=${adventuresIds}&page=0&limit=6`);
    if (!response.ok) {
      return Promise.reject(response.statusText);
    } else {
      return await response.json();
    }
  } catch (error) {
    throw new RequestError(error);
  }
};
