import { RESET_ALL_STATE } from '@redux/constants';
import { ADVENTURES_LIST, FETCH_LIST_ADVENTURES_REQUEST, FETCH_LIST_ADVENTURES_DONE, FETCH_LIST_ADVENTURES_FAILURE } from '@redux/constants/adventures';

type Props = {
  type: string;
  payload: IAdventure[];
};

interface IAdventureState {
  ids: string;
  list: IAdventure[];
  status: string;
  error: string | null;
}

const STATE_TEMPLATE: IAdventureState = {
  ids: '',
  list: [],
  status: 'idle',
  error: null
};

const INITIAL_STATE: IAdventureState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
      return {
        ...STATE_TEMPLATE
      };
    case ADVENTURES_LIST:
      return {
        ...state,
        ids: payload
      };
    case FETCH_LIST_ADVENTURES_REQUEST:
      return {
        ...state,
        list: [],
        status: 'loading',
        error: null
      };
    case FETCH_LIST_ADVENTURES_DONE:
      return {
        ...state,
        list: payload,
        status: 'done',
        error: null
      };
    case FETCH_LIST_ADVENTURES_FAILURE:
      return {
        ...state,
        list: [],
        status: 'error',
        error: 'Error loading experiences'
      };
    default:
      return state;
  }
};
