import { RESET_ALL_STATE } from '@redux/constants';
import { LOG_OUT, SWITCH_ACCOUNT } from '@redux/constants/authentication';
import { FETCH_USER_REQUEST, FETCH_USER_DONE, FETCH_USER_FAILURE, FETCH_USER_UPDATE, GUEST_USER } from '@redux/constants/user';
import { IUser } from '@schemas/IUser';

type Props = {
  type: string;
  payload: IUser;
};

interface IUserState {}

const STATE_TEMPLATE: IUserState = {
  id: null,
  commonProfile: null,
  guestProfile: null,
  userSettings: null,
  authType: null,
  status: 'idle',
  error: null
};

const INITIAL_STATE: IUserState = {
  ...STATE_TEMPLATE
};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case RESET_ALL_STATE:
    case LOG_OUT:
      return {
        ...STATE_TEMPLATE
      };
    case FETCH_USER_REQUEST:
      return {
        id: null,
        commonProfile: null,
        guestProfile: null,
        userSettings: null,
        authType: null,
        status: 'loading',
        error: null
      };
    case FETCH_USER_DONE:
      return {
        ...state,
        ...payload,
        status: 'done',
        error: null
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: 'Something went wrong'
      };
    case FETCH_USER_UPDATE:
      return {
        ...state,
        commonProfile: payload.commonProfile
      };
    case GUEST_USER:
      return {
        ...state,
        guestProfile: payload
      };
    case SWITCH_ACCOUNT:
      return {
        STATE_TEMPLATE
      };
    default:
      return state;
  }
};
