import { API_URL } from '@constants/general';

export class RequestError extends Error {
  constructor(message: string | any) {
    super(message);
    this.name = 'RequestError';
  }
}

type APIFetchAdventure = (adventureId: number) => void;

export const fetchAdventureAPI: APIFetchAdventure = async (adventureId: number) => {
  try {
    const response = await fetch(`${API_URL}experiences/${adventureId}?userType=guest&hostProfileAccessToken=wNWu0Xc0Jg3XHPnHH09X`);
    if (!response.ok) {
      return Promise.reject(response.statusText);
    } else {
      return await response.json();
    }
  } catch (error) {
    throw new RequestError(error);
  }
};
