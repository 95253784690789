import { DATE_FORMATS } from '@redux/constants/booking';
import { IAvailableSlot, ISlotMoment } from '@schemas/IAvailableSlot';
import { IBookingComplete, IBookingSummary } from '@schemas/IBooking';
import { ICart } from '@schemas/ICart';
const moment = require('moment');

export const adventureCity = (city: string): string => {
  const citySplit = city.split(',');
  return citySplit[0];
};

export const getAvailability = (slots: ISlot[]): IAvailableSlot[] => {
  const availableSlots: IAvailableSlot[] = [];
  slots.forEach(slot => {
    const availableSlot: IAvailableSlot = {
      exceptionDates: slot.exceptionDates,
      id: slot.id,
      recurrenceEndDate: slot.recurrenceEndDate,
      recurrentRate: slot.recurrentRate,
      recurringType: slot.recurringType,
      startDate: slot.startDate,
      unavailableDates: slot.unavailableDates,
      startDateString: moment(slot.startDate).format('YYYY-MM-DD'),
      startTimeString: moment(slot.startDate).format('HH:mma'),
      endDateString: slot.recurrenceEndDate ? moment(slot.recurrenceEndDate).format('YYYY-MM-DD') : undefined,
      endTimeString: slot.recurrenceEndDate ? moment(slot.recurrenceEndDate).format('HH:mma') : undefined,
      startDateObject: {
        year: moment(slot.startDate).format('YYYY'),
        month: moment(slot.startDate).format('MM'),
        day: moment(slot.startDate).format('DD')
      },
      requestToBook: slot.requestToBook
    };
    availableSlots.push(availableSlot);
  });
  return availableSlots;
};

const checkDate = (excludeDates: any[], currentDate: { tz: (arg0: any) => any }, timezone: string) => {
  if (!excludeDates || excludeDates.length < 1) return true;
  const exMatches = excludeDates.filter(ex =>
    moment(ex)
      .tz(timezone)
      .isSame(currentDate.tz(timezone), 'day')
  );
  return exMatches.length === 0;
};

export const getAvailableDates = (timeSlots: ISlot[], month: number, year: number, timezone: string, availabilityCutoff: any, afterToday = false) => {
  if (!timeSlots || (timeSlots && !timeSlots.length)) {
    return [];
  }
  const availableDates: { id: number; requestToBook: boolean; momentToString: any; slot: any }[] = [];

  timeSlots.forEach((slot: ISlot) => {
    if (moment(slot.startDate).get('year') > new Date().getFullYear() && moment(slot.startDate).get('year') !== year) {
      return;
    }
    const slotId = slot.id;
    const slotMoment = moment(slot.startDate).tz(timezone);
    const slotEnd = slot.recurrenceEndDate ? moment(slot.recurrenceEndDate).tz(timezone) : null;
    const excludeDates = slot.unavailableDates || [];
    const exceptionDates = slot.exceptionDates || [];
    if (exceptionDates && exceptionDates.length) {
      excludeDates.push.apply(excludeDates, exceptionDates); // eslint-disable-line
    }
    const recurrencePeriod = slot.recurrentRate || 1;
    const slotDay = slotMoment.day();
    const slotDate = slotMoment.date();

    slotMoment.year(year ? year : new Date().getFullYear());
    slotMoment.month(month);

    const endOfMonth = moment(slotMoment).endOf('month');

    // Process slot based on its recurrence period
    switch (slot.recurringType) {
      case 'WEEK':
        if (moment(slot.startDate).get('month') === month && moment(slot.startDate).get('year') === year) {
          slotMoment.date(slotMoment.get('date'));
        } else if (moment(slot.startDate).get('month') > month && moment(slot.startDate).get('year') === year) {
          break;
        } else {
          const slotMomentCopy = moment(slot.startDate);
          let monthYearReached = false;
          let Month = null;
          let Year = null;

          // we need to set the start date , so we keep looping until the current month/year is reached
          // eg : say 2 jan 2019 was the begin date for a bi-weeky/weekly, we need to find out what begin date
          // will it be for the current month
          while (!monthYearReached) {
            slotMomentCopy.add(7 * recurrencePeriod, 'd');
            Month = slotMomentCopy.get('month');
            Year = slotMomentCopy.get('year');
            if (Month === month && Year === year) {
              monthYearReached = true;
            }
          }
          slotMoment.date(slotMomentCopy.get('date')); // set the begin date for current month
        }
        // Restore the original day of the week
        slotMoment.day(slotDay);
        while (slotMoment.isSameOrBefore(endOfMonth, 'day') && !slotMoment.isAfter(slotEnd, 'day')) {
          if (checkDate(excludeDates, slotMoment, timezone)) availableDates.push({ id: slotId, requestToBook: slot.requestToBook, momentToString: moment(slotMoment).format('LLLL'), slot: moment(slotMoment) });
          slotMoment.add(7 * recurrencePeriod, 'd');
        }
        break;
      case 'DAY':
        if (moment(slot.startDate).get('month') === month && moment(slot.startDate).get('year') === year) {
          slotMoment.date(slotMoment.get('date'));
        } else if (moment(slot.startDate).get('month') > month && moment(slot.startDate).get('year') === year) {
          break;
        } else {
          if (moment(slot.startDate).get('year') > new Date().getFullYear()) {
            // break;
          }
          slotMoment.date(1);
        }
        while (slotMoment.isSameOrBefore(endOfMonth, 'day') && !slotMoment.isAfter(slotEnd, 'day')) {
          if (checkDate(excludeDates, slotMoment, timezone)) availableDates.push({ id: slotId, requestToBook: slot.requestToBook, momentToString: moment(slotMoment).format('LLLL'), slot: moment(slotMoment) });
          slotMoment.add(1 * recurrencePeriod, 'd');
        }
        break;
      case 'MONTH':
        if (moment(slot.startDate).get('month') > month && moment(slot.startDate).get('year') === year) {
          break;
        }

        // skip if day of month doesn't exist in current month e.g. 30th day when current month is february
        if (slotMoment.daysInMonth() < slotDate) {
          break;
        }

        if (
          !moment(slotMoment)
            .date(slotDate)
            .isAfter(slotEnd, 'day')
        ) {
          if (checkDate(excludeDates, slotMoment, timezone)) availableDates.push({ id: slotId, requestToBook: slot.requestToBook, momentToString: moment(slotMoment).format('LLLL'), slot: moment(slotMoment.date(slotDate)) });
        }
        break;
      default:
        // this is 'NONE'
        if (moment(slot.startDate).get('month') === month && moment(slot.startDate).get('year') === year) {
          if (checkDate(excludeDates, slotMoment, timezone)) availableDates.push({ id: slotId, requestToBook: slot.requestToBook, momentToString: moment(slotMoment).format('LLLL'), slot: moment(slot.startDate) });
        }
    }
  });
  if (availabilityCutoff) {
    return availableDates.filter(
      slot =>
        slot.slot
          .tz(timezone)
          .clone()
          .format('YYYY-MM-DDTHH:mm:SSS')
          .valueOf() >=
        moment()
          .tz(timezone)
          .add(availabilityCutoff, 'ms')
          .format('YYYY-MM-DDTHH:mm:SSS')
          .valueOf()
    ); // eslint-disable-line
  }
  if (afterToday) {
    return availableDates.filter(
      slot =>
        slot.slot
          .tz(timezone)
          .clone()
          .format('YYYY-MM-DDTHH:mm:SSS')
          .valueOf() >=
        moment()
          .tz(timezone)
          .format('YYYY-MM-DDTHH:mm:SSS')
          .valueOf()
    ); // eslint-disable-line
  }
  return availableDates;
};

export const getNextAvailableSlots = (slots: ISlot[], timezone: string, availabilityCutoff: boolean | number, startDate: any, endDate: any, N = 40): ISlotMoment[] => {
  let month = startDate ? startDate.getMonth() : new Date().getMonth(); // 0 based month
  const year = startDate ? startDate.getFullYear() : new Date().getFullYear();
  let monthCounter = 0;
  let yearCounter = 0;
  const nSlots = [];

  // Look for available dates in the coming 2 year(s)
  for (let i = 0; i < 40; i++) {
    let res = getAvailableDates(slots, month + monthCounter, year + yearCounter, timezone, availabilityCutoff);
    if (availabilityCutoff) {
      res = res.filter(
        slot =>
          slot.slot
            .tz(timezone)
            .clone()
            .format('YYYY-MM-DDTHH:mm:SSS')
            .valueOf() >=
          moment()
            .tz(timezone)
            .add(availabilityCutoff, 'ms')
            .format('YYYY-MM-DDTHH:mm:SSS')
            .valueOf()
      ); // eslint-disable-line
    } else {
      res = res.filter(
        slot =>
          slot.slot
            .tz(timezone)
            .clone()
            .format('YYYY-MM-DDTHH:mm:SSS')
            .valueOf() >=
          moment()
            .tz(timezone)
            .format('YYYY-MM-DDTHH:mm:SSS')
            .valueOf()
      );
    }
    if (startDate) {
      res = res.filter(slot => slot.slot.tz(timezone).valueOf() >= startDate.setHours(0, 0, 0).valueOf());
    }
    if (endDate) {
      res = res.filter(slot => slot.slot.tz(timezone).valueOf() <= endDate.setHours(23, 59, 59).valueOf());
    }
    res = res.sort((SlotA, SlotB) => SlotA.slot.valueOf() - SlotB.slot.valueOf());

    nSlots.push(...res);
    if (nSlots.length >= N) {
      break;
    }
    if (month + monthCounter === 11) {
      month = 0;
      monthCounter = 0;
      yearCounter++;
    } else {
      monthCounter++;
    }
  }
  return nSlots.slice(0, N);
};

export const getPrice = (price: number, priceScheme: any = {}) => {
  const details: any = Object.entries(priceScheme);
  if (details.length > 1) {
    const variablePrice = Math.round(details[details.length - 1][1]);
    return `From $${variablePrice}/ attendee`;
  } else {
    return `$${price}/ attendee`;
  }
};

export const getBookingSummary = (adventure: IAdventure, bookigData: IBookingComplete, cart: ICart): IBookingSummary => {
  let unitPrice = adventure.price;
  let price = adventure.price;
  if (adventure && adventure.priceScheme) {
    price = adventure.priceScheme[bookigData.guestCount || 0];
    unitPrice = adventure.priceScheme[0];
  }
  if (bookigData.guestCount) {
    price *= bookigData.guestCount;
  }
  let tax = 0;
  let discount = 0;
  let subtotal = 0;
  let total = 0;
  if (cart.totals) {
    tax = cart.totals.tax;
    discount = cart.totals.discount;
    subtotal = cart.totals.subtotal;
    total = cart.totals.payable;
  }

  return { unitPrice, price: price * 100, tax, discount, subtotal, total };
};

export const getAdventurePath = (adventure: IAdventure) => {
  try {
    const { id, name } = adventure;
    const words = name.split(' ').filter(w => !!w);
    const activityPath = [id, ...words].join('-');
    return encodeURIComponent(activityPath);
  } catch (e) {
    return null;
  }
};

export const cropImage = (imageURL: string, width: number, height: number, allParams = null, mode = 'fill', gravity = 'auto:subject'): string | null => {
  if (!imageURL) return null;
  if (!imageURL.toLowerCase().includes('cloudinary') || !imageURL.toLowerCase().includes('image/upload') || !width || !height || !mode || !gravity) return imageURL;
  let modString = `image/upload/w_${width},h_${height},c_${mode},g_${gravity}`;
  if (allParams) {
    modString = `image/upload/w_${width},h_${height},${allParams}`;
  }
  return imageURL.replace('image/upload', modString).replace('//e_improve', '');
};

export const dataURLToBlob = (dataURL: string) => {
  try {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLen = raw.length;
    const bytes = new Uint8Array(rawLen);
    for (let i = 0; i < rawLen; i++) {
      bytes[i] = raw.charCodeAt(i);
    }
    return new Blob([bytes], { type: contentType });
  } catch (e) {
    return null;
  }
};

export const getStartTimeEndTime = (slot: any, duration: number) => {
  const startTimeToString = moment(slot).format(DATE_FORMATS.SUMMARY_TIME);
  const endTmeToString = moment(slot)
    .add(duration, 'hours')
    .format(DATE_FORMATS.SUMMARY_TIME);
  return { startTimeToString, endTmeToString };
};
