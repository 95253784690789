export enum DATE_FORMATS {
  SUMMARY_DATE = 'dddd, MMM DD, YYYY',
  SUMMARY_DATE_SHORT = 'ddd, MMM DD, YYYY',
  SUMMARY_TIME = 'hh:mma',
  SUMMARY_TIME_SHORT = 'hh:mma'
}

export const BOOKING_COMPLETED_STEPS: 'BOOKING_COMPLETED_STEPS' = 'BOOKING_COMPLETED_STEPS';

export enum CURRENT_STEPS {
  PARAMETERS = 0,
  DETAILS = 1,
  THANKYOU = 2
}
export const BOOKING_CURRENT_STEP: 'BOOKING_CURRENT_STEP' = 'BOOKING_CURRENT_STEP';

export enum CURRENT_DETAILS_STEP {
  LOGIN = 0,
  GUEST = 1,
  ADDITIONAL_GUESTS = 2,
  WAIVER = 3,
  PAYMENT = 4,
  PAID = 5
}

export enum CURRENT_DETAILS_AUTHENTICATION_METHOD {
  LOGIN = 0,
  CREATE = 1,
  GUEST = 2,
  NONE = 3
}

export const LOGIN_BUTTON_LABEL: string = 'Log in with a Yervana account';
export const LOGOUT_BUTTON_LABEL: string = 'Log out';
export const SIGNUP_BUTTON_LABEL: string = 'Sign up with a Yervana account';
export const CHECKOUT_AS_A_GUEST_BUTTON_LABEL = 'Checkout as a guest';

export const BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD: 'BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD' = 'BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD';
export const BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD_OPEN: 'BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD_OPEN' = 'BOOKING_CURRENT_DETAILS_AUTHENTICATION_METHOD_OPEN';
export const BOOKING_CURRENT_DETAILS_STEP: 'BOOKING_CURRENT_DETAILS_STEP' = 'BOOKING_CURRENT_DETAILS_STEP';

export const BOOKING_UPDATE_PARAMETERS: 'BOOKING_UPDATE_PARAMETERS' = 'BOOKING_UPDATE_PARAMETERS';
export const BOOKING_UPDATE_DETAILS: 'BOOKING_UPDATE_DETAILS' = 'BOOKING_UPDATE_DETAILS';

export const BOOKING_SET_WAIVER: 'BOOKING_SET_WAIVER' = 'BOOKING_SET_WAIVER';
export const BOOKING_SET_ADDITIONAL_GUESTS: 'BOOKING_SET_ADDITIONAL_GUESTS' = 'BOOKING_SET_ADDITIONAL_GUESTS';

export const FETCH_SLOTS_REQUEST: 'FETCH_SLOTS_REQUEST' = 'FETCH_SLOTS_REQUEST';
export const FETCH_SLOTS_DONE: 'FETCH_SLOTS_DONE' = 'FETCH_SLOTS_DONE';
export const FETCH_SLOTS_FAILURE: 'FETCH_SLOTS_FAILURE' = 'FETCH_SLOTS_FAILURE';
export const FETCH_SLOTS_PER_DAY_REQUEST: 'FETCH_SLOTS_PER_DAY_REQUEST' = 'FETCH_SLOTS_PER_DAY_REQUEST';
export const FETCH_SLOTS_PER_DAY_DONE: 'FETCH_SLOTS_PER_DAY_DONE' = 'FETCH_SLOTS_PER_DAY_DONE';
export const FETCH_SLOTS_PER_DAY_FAILURE: 'FETCH_SLOTS_PER_DAY_FAILURE' = 'FETCH_SLOTS_PER_DAY_FAILURE';
export const FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST: 'FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST' = 'FETCH_SLOTS_NEXT_AVAILABILITY_REQUEST';
export const FETCH_SLOTS_NEXT_AVAILABILITY_DONE: 'FETCH_SLOTS_NEXT_AVAILABILITY_DONE' = 'FETCH_SLOTS_NEXT_AVAILABILITY_DONE';
export const FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE: 'FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE' = 'FETCH_SLOTS_NEXT_AVAILABILITY_FAILURE';

export const FETCH_YERVANA_WAIVER_REQUEST: 'FETCH_YERVANA_WAIVER_REQUEST' = 'FETCH_YERVANA_WAIVER_REQUEST';
export const FETCH_YERVANA_WAIVER_DONE: 'FETCH_YERVANA_WAIVER_DONE' = 'FETCH_YERVANA_WAIVER_DONE';

export const FETCH_CARDS_REQUEST: 'FETCH_CARDS_REQUEST' = 'FETCH_CARDS_REQUEST';
export const FETCH_CARDS_DONE: 'FETCH_CARDS_DONE' = 'FETCH_CARDS_DONE';
export const FETCH_CARDS_FAILURE: 'FETCH_CARDS_FAILURE' = 'FETCH_CARDS_FAILURE';

export const POST_CARD_IS_VISIBLE: 'POST_CARD_IS_VISIBLE' = 'POST_CARD_IS_VISIBLE';
export const POST_CARD_REQUEST: 'POST_CARD_REQUEST' = 'POST_CARD_REQUEST';
export const POST_CARD_DONE: 'POST_CARD_DONE' = 'POST_CARD_DONE';
export const POST_CARD_FAILURE: 'POST_CARD_FAILURE' = 'POST_CARD_FAILURE';
export const POST_CARD_RESET: 'POST_CARD_RESET' = 'POST_CARD_RESET';

export const BOOKING_SELECTED_DATE: 'BOOKING_SELECTED_DATE' = 'BOOKING_SELECTED_DATE';
export const BOOKING_SELECTED_SLOT: 'BOOKING_SELECTED_SLOT' = 'BOOKING_SELECTED_SLOT';

export const POST_WAIVER_REQUEST: 'POST_WAIVER_REQUEST' = 'POST_WAIVER_REQUEST';
export const POST_WAIVER_DONE: 'POST_WAIVER_DONE' = 'POST_WAIVER_DONE';
export const POST_WAIVER_FAILURE: 'POST_WAIVER_FAILURE' = 'POST_WAIVER_FAILURE';

export const POST_BOOKING_COMPLETE_REQUEST: 'POST_BOOKING_COMPLETE_REQUEST' = 'POST_BOOKING_COMPLETE_REQUEST';
export const POST_BOOKING_COMPLETE_DONE: 'POST_BOOKING_COMPLETE_DONE' = 'POST_BOOKING_COMPLETE_DONE';
export const POST_BOOKING_COMPLETE_FAILURE: 'POST_BOOKING_COMPLETE_FAILURE' = 'POST_BOOKING_COMPLETE_FAILURE';
