export const WIDGET_URL = process.env.REACT_APP_WIDGET_URL;
export const API_URL = process.env.REACT_APP_API_URL;

// KEYS
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export enum YERVANA_THEME {
  DARK_GREY = '#3f4645',
  DARK_GREEN = '#2F5856'
}
