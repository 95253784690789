import React from 'react';
import { IBreakpoint, IWindowSize } from '@schemas/media/MediaTypes';
import useMedia from '@hooks/useMedia';

export type MediaContextType = {
  breakpoint: IBreakpoint | null;
  windowDimensions: IWindowSize | null;
};

export const MediaContext = React.createContext<MediaContextType | null>(null);

const MediaProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { media, windowSize } = useMedia();
  const [breakpoint, setBreakpoint] = React.useState<IBreakpoint | null>(null);
  const [windowDimensions, setWindowDimensions] = React.useState<IWindowSize | null>(null);

  React.useEffect(() => {
    setBreakpoint(media);
    setWindowDimensions(windowSize);
  }, [media, windowSize]);

  return <MediaContext.Provider value={{ breakpoint, windowDimensions }}>{children}</MediaContext.Provider>;
};

export default MediaProvider;
